<link href="//maxcdn.bootstrapcdn.com/bootstrap/3.3.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//maxcdn.bootstrapcdn.com/bootstrap/3.3.0/js/bootstrap.min.js"></script>
<script src="//code.jquery.com/jquery-1.11.1.min.js"></script>

<hr>
<div class="card">
    <div class="card-header">
        Draft Optimization
    </div>
    <div class="card-body">
        <div class="example-container">
            <form class="example-container" [formGroup]="draftForm" novalidate (ngSubmit)="calculateDraftOptimization(draftForm.value)">

                <div class="container">
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <mat-form-field>
                                <input matInput placeholder="Enter Client Id" required formControlName="clientId">
                            </mat-form-field>
                        </div>
                        <div class="col-sm-4">
                            <button type="submit" mat-raised-button color="primary" [disabled]="draftForm.invalid">Submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

    <div *ngIf="draftModificationResponse">

        <div class="card" *ngIf="draftModificationResponse">

            <div class="card-body">

                <mat-accordion *customFor="let underwritingDetail of draftModificationResponse; let i = index">

                    <mat-expansion-panel class="specific-class" style="border:1px solid;">

                        <mat-expansion-panel-header>

                            <mat-panel-title>UnderWriting Details {{i+1}}</mat-panel-title>

                        </mat-expansion-panel-header>

                        <b>
                            <label>Settlement Details</label>
                        </b>
                        <br>
                        <table class="table table-bordered">
                            <thead class="thead-light">
                                <th>Account Id</th>
                                <th>Beginning Balance</th>
                                <th>CurrentBalance</th>
                                <th>Creditor Id</th>
                                <th>Creditor Name</th>
                                <th>Settlement Amount</th>
                                <th>Savings</th>
                                <th>Month</th>
                            </thead>
                            <tr *ngFor="let obj of underwritingDetail.underwritingSettlementDetails">
                                <td>{{obj.accountId}}</td>
                                <td>{{obj.beginningBalance}}</td>
                                <td>{{obj.currentBalance}}</td>
                                <td>{{obj.currentCreditorId}}</td>
                                <td>{{obj.creditorName}}</td>
                                <td>{{obj.settlementAmount}}</td>
                                <td>{{obj.savings}}</td>
                                <td>{{obj.month}}</td>
                            </tr>
                        </table>

                        <b>
                            <label>Graduation Details</label>
                        </b>
                        <br>
                        <table class="table table-bordered">
                            <thead class="thead-light">
                                <th>Total Savings</th>
                                <th>Total Program Cost</th>
                                <th *ngIf="null != underwritingDetail.optimalDraftAmount">Optimal Draft Amount</th>
                                <th>Draft Amount</th>
                                <th>First Settlement Month</th>
                                <th>Setup for Success</th>
                                <th>Version Type</th>
                                <th>Is Graduated</th>
                            </thead>
                            <tr>
                                <td>{{underwritingDetail.totalSavings}}</td>
                                <td>{{underwritingDetail.totalProgramCost}}</td>
                                <td *ngIf="null != underwritingDetail.optimalDraftAmount">{{underwritingDetail.optimalDraftAmount}}</td>
                                <td>{{underwritingDetail.draftAmount}}</td>
                                <td>{{underwritingDetail.firstSettlementMonth}}</td>
                                <td>{{underwritingDetail.setupForSuccess}}</td>
                                <td>{{underwritingDetail.versionType}}</td>
                                <td>{{underwritingDetail.graduated}}</td>
                            </tr>
                        </table>
                    </mat-expansion-panel>

                </mat-accordion>

            </div>

        </div>

    </div>

</div>