export const environment = {
  production: true,
  envName: 'prod',
  ROADMAP_UNDERWRITING_SECURITY_SOURCE_ID: '0010v00000LQI1yAAH',
  ROADMAP_UNDERWRITING_SECURITY_TOKEN: 'qZxCyNIXfa2h8R4sN7k6J7O8GvDEnghRJrMRW2ifO7jTM7u1GMNwl3YbsTl4xAee7AMhHC9fX07tSdvmz2HQ5sZ6ZeyqHz4Gb4M9',
  SR_BASE_API_URL: 'https://crm-settlement-readiness.prd.fdrgcp.com',
  ROADMAP_SECURITY_SOURCE_ID: '0010v00000LQI1yAAz',
  ROADMAP_SECURITY_TOKEN: 'gHCCyNIXfa2h8R4sN7k6J7O8GvDEnghRJr786JHpoifO7jTM7u1GMNwl3YbsTl4xAee7AMhHC9fX07tSdvmz2HQ5sZ6ZeyqHz4G8hBBd',
  AUTH_BASE_API_URL: 'https://crm-sif-api.prd.fdrgcp.com/v1/auth'
};
