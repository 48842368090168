var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { AUTO_LOGIN, LOGIN, LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT, } from "./user.login.actions";
var initialState = {
    isLoggedIn: false,
    username: '',
    isServiceFailedToLogin: false,
    forcedLogout: false
};
export function PersonReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case LOGOUT:
            return Object.assign({}, initialState, {
                forcedLogout: action.forcedToLogout
            });
        case LOGIN:
            return __assign({}, state, { username: action.userData.username });
        case AUTO_LOGIN:
            return __assign({}, state, { isLoggedIn: action.userdata.isLoggedIn, username: action.userdata.username, isServiceFailedToLogin: false });
        case LOGIN_SUCCESS:
            return __assign({}, state, { isLoggedIn: true });
        case LOGIN_FAILURE:
            return __assign({}, state, { isLoggedIn: false, isServiceFailedToLogin: true });
        default:
            return state;
    }
}
