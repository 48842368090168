<hr>
<div class="card">

	<div *ngIf="settlementResponse">
		<div *ngIf="settlementResponse.debtAccounts == null">
			<div *ngIf="settlementResponse.remarks">
				{{settlementResponse.remarks}}
			</div>
		</div>
		<div class="card" *ngIf="settlementResponse.debtAccounts">
			<div class="card-header">

				<b>
					<label> Client Id </label> : </b>
				<i>{{settlementResponse.clientId}}
					<b>,</b>&nbsp; </i>
				<b>
					<label> Enrollment Date</label> :</b>
				<i>{{settlementResponse.enrollmentDate}}
					<b>,</b>&nbsp; </i>
				<b>
					<label> No. Resolved Accounts</label>
				</b> :
				<i>{{settlementResponse.resolvedAccounts}} </i>
				<b>
					<label> Month Since Last Settlement : &nbsp;</label>
				</b>
				<i>{{settlementResponse.monthSinceLastSettlement}} </i>
				<b>
					<label *ngIf="settlementResponse.regionOneSelectedAccountHighestSR != null"> , Region One Selected Highest SR : &nbsp;</label>
				</b>
				<i>{{settlementResponse.regionOneSelectedAccountHighestSR}} </i>
				<b>
					<label *ngIf="settlementResponse.regionTwoSelectedAccountHighestSR != null"> , Region Two Selected Highest SR : &nbsp;</label>
				</b>
				<i>{{settlementResponse.regionTwoSelectedAccountHighestSR}} </i>
				<b>
					<label *ngIf="settlementResponse.regionThreeSelectedAccountHighestSR != null"> , Region Three Selected Highest SR : &nbsp;</label>
				</b>
				<i>{{settlementResponse.regionThreeSelectedAccountHighestSR}} </i>
			</div>
		</div>
		<br>
		<div *ngIf="settlementResponse.settlementDetails">
			<h4>&nbsp;&nbsp; Settlement Details</h4>
		</div>
		<mat-accordion [multi]="true" *ngIf="settlementResponse.settlementDetails">
			<mat-expansion-panel *ngFor="let settlementDetail of settlementResponse.settlementDetails; let i = index" style="border:1px solid;">
				<mat-expansion-panel-header [ngStyle]="{'background-color': colorWhite}">
					<mat-panel-title>

						<b>Account:</b> {{settlementDetail.accountId}}
						<b>,</b>&nbsp;
						<b>Creditor:</b> {{settlementDetail.creditorName}}
						<b *ngIf="settlementDetail.monthYear">,&nbsp;</b>
						<b *ngIf="settlementDetail.monthYear">Settlement Month/Year:</b> {{settlementDetail.monthYear}}
						<b *ngIf="settlementDetail.savings">,&nbsp;</b>
						<i style="color: green;">
							<b *ngIf="settlementDetail.savings">Savings:</b> {{settlementDetail.savings | currency}}</i>
						<b>,&nbsp;</b>
						<i style="color: rgb(255, 94, 0);">
							<b>Month Number:</b> {{settlementDetail.month}}</i>

					</mat-panel-title>
				</mat-expansion-panel-header>
				<table class="table table-bordered">
					<tr *ngIf="settlementDetail.beginningBalance">
						<td>Beginning Balance</td>
						<td>{{settlementDetail.beginningBalance | currency}}</td>
					</tr>
					<tr *ngIf="settlementDetail.currentBalance">
						<td>Current Balance</td>
						<td>{{settlementDetail.currentBalance | currency}}</td>
					</tr>
					<tr *ngIf="settlementDetail.settlementAmount">
						<td>Settlement Amount</td>
						<td>{{settlementDetail.settlementAmount | currency}}</td>
					</tr>
					<tr *ngIf="settlementDetail.payments">
						<td>Payments</td>
						<td>
							<i *ngFor="let payment of settlementDetail.payments; let p = index">
								{{payment.month}} : {{payment.amount | currency}}
								<i *ngIf="p !== settlementDetail.payments.length-1">,</i>
							</i>
						</td>
					</tr>
					<tr *ngIf="settlementDetail.fees">
						<td>Fees</td>
						<td>
							<i *ngFor="let fee of settlementDetail.fees; let f = index">
								{{fee.month}} : {{fee.amount | currency}}
								<i *ngIf="f !== settlementDetail.fees.length-1">,</i>
							</i>
						</td>
					</tr>
				</table>
			</mat-expansion-panel>
		</mat-accordion>
		<br>
		<div>
			<h4>&nbsp;&nbsp; Account Details</h4>
		</div>
		<div class="card-body">
			<mat-accordion *ngFor="let account of settlementResponse.debtAccounts; let i = index">
				<mat-expansion-panel class="specific-class" style="border:1px solid;">
					<mat-expansion-panel-header [ngStyle]="{'background-color':(account.regionThreeAvailableToSettle)
					|| (account.regionTwoAvailableToSettle)
					|| (account.regionOneAvailableToSettle)? 'green' : colorMildRed}">
						<mat-panel-title [ngStyle]="{'color':'white'}">
							<b>{{account.accountId}}</b>
						</mat-panel-title>
					</mat-expansion-panel-header>
					<table class="table table-bordered">
						<tr>
							<td>Creditor Name</td>
							<td>{{account.creditor.Name}}</td>
						</tr>
						<tr>
							<td>Days Past Due</td>
							<td>{{account.daysPastDue}}</td>
						</tr>
						<tr>
							<td>Beginning Balance</td>
							<td>{{account.beginningBalance | currency}}</td>
						</tr>
						<tr>
							<td>Current Balance</td>
							<td>{{account.currentBalance | currency}}</td>
						</tr>
						<tr>
							<td>Available To Settle Date</td>
							<td>{{account.availableToSettleDate}}</td>
						</tr>
						<tr>
							<td>Financial Readiness</td>
							<td>{{account.financialReadiness}}</td>
						</tr>
						<tr>
							<td>CutOff Fee Score</td>
							<td>{{account.iterations[0].cutOfFeeScore}}</td>
						</tr>
            <tr *ngIf="account.interestRate">
              <td>Interest Rate</td>
              <td>{{account.interestRate}}</td>
            </tr>
            <tr *ngIf="account.accountType">
              <td>Account Type</td>
              <td>{{account.accountType}}</td>
            </tr>
						<tr *ngIf="true == settlementResponse.region1Client">
							<td>Region1 Available To Settle</td>
							<td>{{account.regionOneAvailableToSettle}}</td>
						</tr>
						<tr *ngIf="settlementResponse.region1Client && account.regionOneAvailableToSettle">
							<td>Region1 Creditor Rank</td>
							<td>{{account.regionOneCreditorRank}}</td>
						</tr>
						<tr *ngIf="settlementResponse.region1Client && account.regionOneAvailableToSettle">
							<td>Region1 Month To Settle</td>
							<td>{{account.regionOneMonthToSettle}}</td>
						</tr>
						<tr *ngIf="account.regionOneAvailableToSettle">
							<td>Region1 Liquidation Rate</td>
							<td>{{account.regionOneLiquidationRate}}</td>
						</tr>
						<tr *ngIf="account.regionOneAvailableToSettle">
							<td>Region1 Liquidation Rate X Beginning Balance</td>
							<td>{{account.regionOneLiquidationRateBeginningBalance}}</td>
						</tr>
						<tr *ngIf="settlementResponse.region2Client">
							<td>Region2 Available To Settle</td>
							<td>{{account.regionTwoAvailableToSettle}}</td>
						</tr>
						<tr *ngIf="settlementResponse.region2Client && account.regionTwoAvailableToSettle">
							<td>Region2 Creditor Rank</td>
							<td>{{account.regionTwoCreditorRank}}</td>
						</tr>
						<tr *ngIf="settlementResponse.region2Client && account.regionTwoAvailableToSettle">
							<td>Region2 Month To Settle</td>
							<td>{{account.regionTwoMonthToSettle}}</td>
						</tr>
						<tr *ngIf="settlementResponse.region2Client && account.regionTwoAvailableToSettle">
							<td>Region2 Avaialble To Settle Offset</td>
							<td>{{account.regionTwoAvailableToSettleOffset}}</td>
						</tr>
						<tr *ngIf="account.regionTwoAvailableToSettle">
							<td>Region2 Liquidation Rate</td>
							<td>{{account.regionTwoLiquidationRate}}</td>
						</tr>
						<tr *ngIf="account.regionTwoAvailableToSettle">
							<td>Region2 Liquidation Rate X Beginning Balance</td>
							<td>{{account.regionTwoLiquidationRateBeginningBalance}}</td>
						</tr>
						<tr *ngIf="settlementResponse.region3Client">
							<td>Region3 Available To Settle</td>
							<td>{{account.regionThreeAvailableToSettle}}</td>
						</tr>
						<tr *ngIf="settlementResponse.region3Client && account.regionThreeAvailableToSettle">
							<td>Region3 Creditor Rank</td>
							<td>{{account.regionThreeCreditorRank}}</td>
						</tr>
						<tr *ngIf="settlementResponse.region3Client && account.regionThreeAvailableToSettle">
							<td>Region3 Month To Settle</td>
							<td>{{account.regionThreeMonthToSettle}}</td>
						</tr>
						<tr *ngIf="account.regionThreeAvailableToSettle">
							<td>Region3 Liquidation Rate</td>
							<td>{{account.regionThreeLiquidationRate}}</td>
						</tr>
						<tr *ngIf="account.regionThreeAvailableToSettle">
							<td>Region3 Liquidation Rate X Beginning Balance</td>
							<td>{{account.regionThreeLiquidationRateBeginningBalance}}</td>
						</tr>
					</table>

					<div class="card-header">
						<b>Creditor Config</b>
					</div>
					<mat-accordion *customFor="let creditorConfig of account.creditorConfigList">
						<mat-expansion-panel class="specific-class" style="border:1px solid;">
							<mat-expansion-panel-header>
								<mat-panel-title>
									{{creditorConfig.Id}}
								</mat-panel-title>
							</mat-expansion-panel-header>
							<table class="table table-bordered">
								<tr *ngIf="null != creditorConfig.Name">
									<td>Name</td>
									<td>{{creditorConfig.Name}}</td>
								</tr>
								<tr *ngIf="null != creditorConfig.Actual_Average_Settlement__c">
									<td>Actual Average Settlement c</td>
									<td>{{creditorConfig.Actual_Average_Settlement__c}}</td>
								</tr>
								<tr *ngIf="null != creditorConfig.Actual_Estimated_Max_Payments__c">
									<td>Actual Estimated Max Payments</td>
									<td>{{creditorConfig.Actual_Estimated_Max_Payments__c}}</td>
								</tr>
								<tr *ngIf="null != creditorConfig.Actual_Minimum_Payment__c">
									<td>Actual Minimum Payment</td>
									<td>{{creditorConfig.Actual_Minimum_Payment__c}}</td>
								</tr>
								<tr *ngIf="null != creditorConfig.Days_Past_Due_Maximum__c">
									<td>Days Past Due Maximum</td>
									<td>{{creditorConfig.Days_Past_Due_Maximum__c}}</td>
								</tr>
								<tr *ngIf="null != creditorConfig.Days_Past_Due_Minimum__c">
									<td>Days Past Due Minimum</td>
									<td>{{creditorConfig.Days_Past_Due_Minimum__c}}</td>
								</tr>
								<tr *ngIf="null != creditorConfig.Estimated_of_Payments_Override__c">
									<td>Estimated of Payments Override</td>
									<td>{{creditorConfig.Estimated_of_Payments_Override__c}}</td>
								</tr>
								<tr *ngIf="null != creditorConfig.Estimated_Settlement_Override__c">
									<td>Estimated Settlement Override</td>
									<td>{{creditorConfig.Estimated_Settlement_Override__c}}</td>
								</tr>
								<tr *ngIf="null != creditorConfig.Extend_First_Month_Payment_Terms__c">
									<td>Extend First Month Payment Terms</td>
									<td>{{creditorConfig.Extend_First_Month_Payment_Terms__c}}</td>
								</tr>
								<tr *ngIf="null != creditorConfig.Last_Payment_Exception__c">
									<td>Last Payment Exception</td>
									<td>{{creditorConfig.Last_Payment_Exception__c}}</td>
								</tr>
								<tr *ngIf="null != creditorConfig.Meta_Current_Creditor__c">
									<td>Meta Current Creditor</td>
									<td>{{creditorConfig.Meta_Current_Creditor__c}}</td>
								</tr>
								<tr *ngIf="null != creditorConfig.Meta_Original_Creditor__c">
									<td>Meta Original Creditor</td>
									<td>{{creditorConfig.Meta_Original_Creditor__c}}</td>
								</tr>
								<tr *ngIf="null != creditorConfig.Month_1_Min_Dollar_Override__c">
									<td>Month 1 Min Dollar Override</td>
									<td>{{creditorConfig.Month_1_Min_Dollar_Override__c}}</td>
								</tr>
								<tr *ngIf="null != creditorConfig.Month_1_Percent_Override__c">
									<td>Month 1 Percent Override</td>
									<td>{{creditorConfig.Month_1_Percent_Override__c}}</td>
								</tr>
								<tr *ngIf="null != creditorConfig.Remaining_Months_Min_Dollar_Override__c">
									<td>Remaining Months Min Dollar Override</td>
									<td>{{creditorConfig.Remaining_Months_Min_Dollar_Override__c}}</td>
								</tr>
								<tr *ngIf="null != creditorConfig.Remaining_Months_Percent_Override__c">
									<td>Remaining Months Percent Override</td>
									<td>{{creditorConfig.Remaining_Months_Percent_Override__c}}</td>
								</tr>
								<tr *ngIf="null != creditorConfig.sfSettlemetSource">
									<td>SF Settlement Source</td>
									<td>{{creditorConfig.sfSettlementSource}}</td>
								</tr>
								<tr *ngIf="null != creditorConfig.Liquidation_Rate__c">
									<td>Liquidation Rate</td>
									<td>{{creditorConfig.Liquidation_Rate__c}}</td>
								</tr>
							</table>
						</mat-expansion-panel>
					</mat-accordion>

					<mat-list-item *ngFor="let iteration of account.iterations ">
						<mat-tab-group>
							<mat-tab label="Month {{iteration.month}}({{iteration.monthYearString}})" *ngIf="false == iteration.pastDate">
								<mat-list-item>
									<label>SR</label> : {{iteration.sr}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Past Date</label> : {{iteration.pastDate}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Available To Settle</label> : {{iteration.availableToSettle}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Days Past Due</label> : {{iteration.daysPastDue}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Creditor Rank</label> : {{iteration.creditorRank}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Settlement Amount</label> : {{iteration.settlementAmount | currency}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Fund Available</label> : {{iteration.fundsAvailable}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Settlement Amount Changed</label> : {{iteration.settlementAmountChanged}}
								</mat-list-item>
								<mat-divider></mat-divider>ß
								<mat-list-item>
									<label>Liquidation Rate</label> : {{iteration.liquidationRate}}
								</mat-list-item>
								<mat-divider></mat-divider>
							</mat-tab>
							<mat-tab label="Fee Score Request" *ngIf="iteration.feeScoreRequest">
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Available To SettleDate</label> : {{iteration.feeScoreRequest.availableToSettleDate}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Estimated Percentage</label> : {{iteration.feeScoreRequest.estimatedPercentage}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Fees To Collect</label> : {{iteration.feeScoreRequest.feesToCollect}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Last Account To Settle</label> : {{iteration.feeScoreRequest.lastAccountToSettle}}
								</mat-list-item>
							</mat-tab>
							<mat-tab label="Fee Score Response" *ngIf="iteration.feeScoreResponse">
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Score</label> : {{iteration.feeScoreResponse.feeScore}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Rule</label> : {{iteration.feeScoreResponse.rule}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Months To Iterate</label> : {{iteration.feeScoreResponse.monthToIterate}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<label>Fees List</label>
								<mat-list-item *ngFor="let fee of iteration.feeScoreResponse.feesList; let k = index">
									<i>{{fee | currency}}</i>
								</mat-list-item>
							</mat-tab>
							<mat-tab label="Payment" *ngIf="iteration.feeScoreRequest">
								<mat-divider></mat-divider>
								<mat-list-item *ngFor="let payment of iteration.feeScoreRequest.payments; let k = index">
									<i>{{payment | currency}}</i>
									<mat-divider></mat-divider>
								</mat-list-item>
							</mat-tab>
							<mat-tab label="Combo" *ngIf="true == iteration.combo || false == iteration.pastDate">
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Id</label> : {{iteration.combo.Id}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Combo Name</label> : {{iteration.combo.Name}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Current Meta Creditor</label> : {{iteration.combo.Current_Meta_Creditor__r.Name}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Estimated_Minimum_Payment_Amt_Actual</label> : {{iteration.combo.Estimated_Minimum_Payment_Amt_Actual__c}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Estimated_Settlement_Pct_Actual</label> : {{iteration.combo.Estimated_Settlement_Pct_Actual__c}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Estimated_Settlement_Pct_Override</label> : {{iteration.combo.Estimated_Settlement_Pct_Override__c}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Estimated_Term_Num_Months_Actual</label> : {{iteration.combo.Estimated_Term_Num_Months_Actual__c}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Estimated_Term_Num_Months_Override</label> : {{iteration.combo.Estimated_Term_Num_Months_Override__c}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Last_Payment_Exception</label> : {{iteration.combo.Last_Payment_Exception__c}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Past_due_Month_1_min_dollar_override</label> : {{iteration.combo.Past_due_Month_1_min_dollar_override__c}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Past_due_Month_1_percent_override</label> : {{iteration.combo.Past_due_Month_1_percent_override__c}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Month_1_Min_Dollar_Override</label> : {{iteration.combo.Month_1_Min_Dollar_Override__c}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Meta_Creditor_Combo_Rank</label> : {{iteration.combo.Meta_Creditor_Combo_Rank__c}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Month_1_percent_override</label> : {{iteration.combo.Month_1_percent_override__c}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Override_Good_Through_Date</label> : {{iteration.combo.Override_Good_Through_Date__c}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Past_Due_Est_Term_of_Months_Override</label> : {{iteration.combo.Past_Due_Est_Term_of_Months_Override__c}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Past_Due_Estimated_Settlement_Override</label> : {{iteration.combo.Past_Due_Estimated_Settlement_Override__c}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Past_due_remaining_months_percent</label> : {{iteration.combo.Past_due_remaining_months_percent__c}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Past_due_remaining_months_min_dollar</label> : {{iteration.combo.Past_due_remaining_months_min_dollar__c}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Remaining_Months_Min_Dollar_Override</label> : {{iteration.combo.Remaining_Months_Min_Dollar_Override__c}}
								</mat-list-item>
								<mat-divider></mat-divider>
								<mat-list-item>
									<label>Remaining_Months_percent_override</label> : {{iteration.combo.Remaining_Months_percent_override__c}}
								</mat-list-item>
								<mat-divider></mat-divider>
							</mat-tab>
						</mat-tab-group>
					</mat-list-item>

				</mat-expansion-panel>
			</mat-accordion>
		</div>
		<br>
		<mat-accordion id="dfli1" *ngIf="settlementResponse.debtAccounts">
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>
						<b>DFLI</b>
					</mat-panel-title>
				</mat-expansion-panel-header>

				<table mat-table [dataSource]="settlementResponse.dfli" class="mat-elevation-z8" style="width: 100%;">

					<!-- monthYear Column -->
					<ng-container matColumnDef="monthYear">
						<th mat-header-cell *matHeaderCellDef>Month & Year</th>
						<td mat-cell *matCellDef="let dfli"> {{dfli.transactionClearDate}}</td>
					</ng-container>

					<!-- transactionType Column -->
					<ng-container matColumnDef="transactionType">
						<th mat-header-cell *matHeaderCellDef>Transaction Type</th>
						<td mat-cell *matCellDef="let dfli"> {{dfli.transactionType}} </td>
					</ng-container>

					<!-- amount Column -->
					<ng-container matColumnDef="amount">
						<th mat-header-cell *matHeaderCellDef>Amount</th>
						<td mat-cell *matCellDef="let dfli" [ngStyle]="{'color':dfli.transactionType === 'Draft' ? 'green' : 'red'}">
							{{dfli.transactionAmount | currency}}
						</td>
					</ng-container>

					<!-- currentbalance Column -->
					<ng-container matColumnDef="currentbalance">
						<th mat-header-cell *matHeaderCellDef>Current Balance</th>
						<td mat-cell *matCellDef="let dfli"> {{dfli.forecastedAccountSPABalance | currency}} </td>
					</ng-container>

					<!-- remark Column -->
					<ng-container matColumnDef="remark">
						<th mat-header-cell *matHeaderCellDef>Remark</th>
						<td mat-cell *matCellDef="let dfli"> {{dfli.remark}} </td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

				</table>

			</mat-expansion-panel>
		</mat-accordion>
		<mat-accordion id="forecaster" *ngIf="settlementResponse.debtAccounts">
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>
						<b>Forecaster</b>
					</mat-panel-title>
				</mat-expansion-panel-header>

				<table class="table table-bordered">
					<tr *ngFor="let forecast of forecaster; let f = index">
						<td *ngFor="let fst of forecast; let f = index">
							<b>{{fst.yearMonthString}}</b>
							<hr>
							<b [ngStyle]="{'color':fst.minimumAmount >= 0 ? 'green' : 'red'}">{{fst.minimumAmount | currency}}</b>
						</td>
					</tr>
				</table>
			</mat-expansion-panel>
		</mat-accordion>
		<mat-accordion id="regionDetail" *ngIf="settlementResponse.regionDetail && null != regionValuesNew && regionValuesNew.length > 0">
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>
						<b>Regional Differences : Region 1</b>
					</mat-panel-title>
				</mat-expansion-panel-header>

				<table class="table table-bordered">
					<th>AccountId</th>
					<th>Rank</th>
					<th *ngIf=" true == settlementResponse.region1Client && null != settlementResponse.regionDetail.regionOne">R1 Amount</th>
					<th *ngIf=" true == settlementResponse.region1Client || true == settlementResponse.region2Client">R2 Amount</th>
					<th *ngIf=" true == settlementResponse.region1Client || true == settlementResponse.region2Client">R3 Amount</th>
					<th>Diff</th>
					<th>Beginning Balance</th>
					<th>Financial Readiness</th>
					<th>Is Selected</th>
					<tr *ngFor="let obj of regionValuesNew">
						<td>{{obj.accountId}}</td>
						<td>{{obj.rank}}</td>
						<td *ngIf="true == settlementResponse.region1Client && null != settlementResponse.regionDetail.regionOne">{{obj.r1SettlementAmount}}</td>
						<td>{{obj.r2SettlementAmount}}</td>
						<td>{{obj.r3SettlementAmount}}</td>
						<td>{{obj.diff}}</td>
						<td>{{obj.beginningBalance}}</td>
						<td>{{obj.fr}}</td>
						<td>{{obj.selected}}</td>
					</tr>
				</table>
			</mat-expansion-panel>
		</mat-accordion>
		<mat-accordion id="regionDetail" *ngIf="settlementResponse.regionDetail && null != regionValuesNewR2 && regionValuesNewR2.length > 0">
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>
						<b>Regional Differences : Region 2</b>
					</mat-panel-title>
				</mat-expansion-panel-header>

				<table class="table table-bordered">
					<th>AccountId</th>
					<th>Rank</th>
					<th *ngIf="  true == settlementResponse.region2Client">R2 Amount</th>
					<th *ngIf="  true == settlementResponse.region2Client">R3 Amount</th>
					<th>Diff</th>
					<th>Beginning Balance</th>
					<th>Financial Readiness</th>
					<th>Is Selected</th>
					<tr *ngFor="let obj of regionValuesNewR2">
						<td>{{obj.accountId}}</td>
						<td>{{obj.rank}}</td>
						<td>{{obj.r2SettlementAmount}}</td>
						<td>{{obj.r3SettlementAmount}}</td>
						<td>{{obj.diff}}</td>
						<td>{{obj.beginningBalance}}</td>
						<td>{{obj.fr}}</td>
						<td>{{obj.selected}}</td>
					</tr>
				</table>
			</mat-expansion-panel>
		</mat-accordion>
		<mat-accordion id="regionDetail" *ngIf="settlementResponse.regionDetail && null != regionValuesNewR3 && regionValuesNewR3.length > 0">
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>
						<b>Regional Differences : Region 3</b>
					</mat-panel-title>
				</mat-expansion-panel-header>

				<table class="table table-bordered">
					<th>AccountId</th>
					<th>Rank</th>
					<th *ngIf="settlementResponse.region3Client">R3 First Settlement Amount</th>
					<th *ngIf="settlementResponse.region3Client">R3 Next Settlement Amount</th>
					<th *ngIf="settlementResponse.region3Client">R3 Settlement Diff</th>
					<th>Beginning Balance</th>
					<th>Financial Readiness</th>
					<th>Is Selected</th>
					<tr *ngFor="let obj of regionValuesNewR3">
						<td>{{obj.accountId}}</td>
						<td>{{obj.rank}}</td>
						<td>{{obj.r2SettlementAmount}}</td>
						<td>{{obj.r3SettlementAmount}}</td>
						<td>{{obj.diff}}</td>
						<td>{{obj.beginningBalance}}</td>
						<td>{{obj.fr}}</td>
						<td>{{obj.selected}}</td>
					</tr>
				</table>
			</mat-expansion-panel>
		</mat-accordion>
	</div>

</div>
