import { DoCheck, EmbeddedViewRef, IterableChangeRecord, IterableChanges, IterableDiffer, IterableDiffers, NgIterable, OnChanges, SimpleChanges, TemplateRef, TrackByFunction, ViewContainerRef } from '@angular/core';
var CustomForOfContext = /** @class */ (function () {
    function CustomForOfContext($implicit, customForOf, index, count) {
        this.$implicit = $implicit;
        this.customForOf = customForOf;
        this.index = index;
        this.count = count;
    }
    Object.defineProperty(CustomForOfContext.prototype, "first", {
        get: function () { return this.index === 0; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomForOfContext.prototype, "last", {
        get: function () { return this.index === this.count - 1; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomForOfContext.prototype, "even", {
        get: function () { return this.index % 2 === 0; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomForOfContext.prototype, "odd", {
        get: function () { return !this.even; },
        enumerable: true,
        configurable: true
    });
    return CustomForOfContext;
}());
export { CustomForOfContext };
var CustomForOf = /** @class */ (function () {
    function CustomForOf(_viewContainer, _template, _differs) {
        this._viewContainer = _viewContainer;
        this._template = _template;
        this._differs = _differs;
        this._differ = null;
    }
    Object.defineProperty(CustomForOf.prototype, "customForTemplate", {
        set: function (value) {
            if (value) {
                this._template = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    CustomForOf.prototype.ngOnChanges = function (changes) {
        if ('customForOf' in changes) {
            var value = changes.customForOf.currentValue;
            if (this._differ || !value) {
                return;
            }
            try {
                this._differ = this._differs.find(value).create(this.customForTrackBy);
            }
            catch (e) {
                throw new Error("Cannot find a differ supporting object '" + value + "' of type '" + getTypeNameForDebugging(value) + "'.");
            }
        }
    };
    CustomForOf.prototype.ngDoCheck = function () {
        if (this._differ) {
            var changes = this._differ.diff(this.customForOf);
            if (changes) {
                this._applyChanges(changes);
            }
        }
    };
    CustomForOf.prototype._applyChanges = function (changes) {
        var viewContainerLength = this._viewContainer.length;
        var dataLength = this.customForOf.length;
        var tuples = {};
        changes.forEachOperation(function (record, _, currentIndex) {
            if (currentIndex !== null) {
                tuples[currentIndex] = record.item;
            }
        });
        for (var i = viewContainerLength; i < dataLength; i++) {
            this._viewContainer.createEmbeddedView(this._template, new CustomForOfContext(null, this.customForOf, -1, -1), i);
        }
        for (var i = this._viewContainer.length; i > dataLength; i--) {
            this._viewContainer.remove(i);
        }
        for (var i = 0; i < this._viewContainer.length; i++) {
            var view = this._viewContainer.get(i);
            view.context.index = i;
            view.context.count = length;
            view.context.$implicit = tuples[i] || null;
        }
    };
    return CustomForOf;
}());
export { CustomForOf };
export function getTypeNameForDebugging(type) {
    return type.name || typeof type;
}
