<link href="//maxcdn.bootstrapcdn.com/bootstrap/3.3.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//maxcdn.bootstrapcdn.com/bootstrap/3.3.0/js/bootstrap.min.js"></script>
<script src="//code.jquery.com/jquery-1.11.1.min.js"></script>

<hr>
<div class="card">
    <div class="card-header">
        Roadmap Underwriting Analysis
    </div>
    <div class="card-body">
        <div class="example-container">
            <form class="example-container" [formGroup]="underwritingForm" novalidate (ngSubmit)="getTransactionInfo(underwritingForm.value)">

                <div class="container">
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <mat-form-field>
                                <input matInput placeholder="Enter Account Id" required formControlName="accountId">
                            </mat-form-field>
                        </div>
                        <div class="col-sm-4">
                            <button type="submit" mat-raised-button color="primary" [disabled]="underwritingForm.invalid">Submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

    <div class="container">
        <div *ngIf="transactionInfoResponse != null">
            <div class="form-group row">

                <div class="col-sm-2">
                    <label htmlFor="client">
                        Client Name : {{client}}
                    </label>
                </div>

                <div class="col-sm-4">
                    <select class="form-control" [(ngModel)]="selectedTxn" (ngModelChange)="selectTxnId(selectedTxn)">
                        <option *ngFor="let txn of transactionInfoResponse" [value]="txn.transactionId">
                            {{txn.transactionId}}
                        </option>
                    </select>
                </div>

                <div class="col-sm-4">
                    <button type="submit" mat-raised-button color="primary" (click)="analysizeUnderwritingRoadmap()">Submit</button>
                </div>

            </div>
        </div>
    </div>

</div>