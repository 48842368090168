import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { map } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { AUTO_LOGIN_ACTION } from "../store/user.login.actions";
import { LocalStorageService, STORAGE_KEYS } from "../services/localstorage.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ngrx/store";
import * as i3 from "../services/localstorage.service";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, store, localStorageService) {
        this.router = router;
        this.store = store;
        this.localStorageService = localStorageService;
    }
    AuthGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        var localData = this.localStorageService.readDataFromLocalStorage(STORAGE_KEYS.USER_LOGIN);
        if (localData != undefined) {
            this.store.dispatch(new AUTO_LOGIN_ACTION(localData));
        }
        return this.store.select("person").pipe(map(function (person) {
            if (person.isLoggedIn) {
                return true;
            }
            _this.router.navigate([""]);
            return false;
        }));
    };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i1.Router), i0.inject(i2.Store), i0.inject(i3.LocalStorageService)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
