<hr>
<div class="card">
	<div class="card-header">
		Creditor Bucket Details
	</div>
	<div class="card-body">
		<div class="example-container">
			<form class="example-container" [formGroup]="dwaForm" novalidate (ngSubmit)="getBucketDetails(dwaForm.value)">
				<mat-form-field> 
					<input matInput placeholder="Creditor Name" required formControlName="creditor" 
					[inputFormatter]="formatter" [resultFormatter]="formatter" [ngbTypeahead]="search">
				</mat-form-field>
				<button type="submit" mat-raised-button color="primary" [disabled]="dwaForm.invalid">Display</button>
			</form>
		</div>
		<div class="col-md-12" *ngIf="pieChartData.length">
			<canvas baseChart
				[data]="pieChartData"
				[labels]="pieChartLabels"
				[chartType]="pieChartType"
				(chartHover)="chartHovered($event)"
				(chartClick)="chartClicked($event)">
			</canvas>
		</div>
	</div>
</div>