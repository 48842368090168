<div class="container-fluid">
  <hr>
    <div class="col-12">
      <form class="form-inline" action="#" name="searchFrm" id="searchFrm">
        <div class="form-group">
          <label for="eventType" class="col-3 col-form-label">Event Type:</label>
          <select class="form-control" id="eventType" name="eventType" #eventType required (change)="onEventChange($event.target.value)">
              <option value="">Select Event Type</option>
              <option value={{eventType.value}} *ngFor="let eventType of eventTypes">{{eventType.label}}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="subType" class="col-3 col-form-label">Case Type:</label>
          <select class="form-control" id="subType" name="subType" #subType required>
              <option value="">Select Case Type</option>
              <option value={{caseType.value}} *ngFor="let caseType of caseTypes">{{caseType.label}}</option>
          </select>
        </div>
        <div class="form-group">
          <label class="col-2 col-form-label" for="Id">Id:</label>
          <input type="text" class="form-control" id="id" name="id" #id required>
        </div>
        <button type="button" class="btn btn-default" (click)="getEventsData(eventType.value, subType.value, id.value)">Search</button>
      </form>
  </div>
  <hr>
  <div class="mat-elevation-z8" *ngIf="events">
    <table mat-table [dataSource]="events" matSort>
      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> No. </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>
    
      <!-- Title Column -->
      <ng-container matColumnDef="caseType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> caseType </th>
        <td mat-cell *matCellDef="let element"> {{element.caseType}} </td>
      </ng-container>
    
      <!-- Category Column -->
      <ng-container matColumnDef="sourceSystem">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> sourceSystem </th>
        <td mat-cell *matCellDef="let element"> {{element.sourceSystem}} </td>
      </ng-container>
    
      <!-- Writer Column -->
      <ng-container matColumnDef="subEventType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> subEventType </th>
        <td mat-cell *matCellDef="let element"> {{element.subEventType}} </td>
      </ng-container>

      <!-- Writer Column -->
      <ng-container matColumnDef="txnId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> txnId </th>
        <td mat-cell *matCellDef="let element"> {{element.txnId}} </td>
      </ng-container>

      <!-- Writer Column -->
      <ng-container matColumnDef="lastUpdated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> lastUpdated </th>
        <td mat-cell *matCellDef="let element"> {{element.lastUpdated}} </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[20]"></mat-paginator>
  </div>