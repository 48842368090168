import { OnInit } from '@angular/core';
import { SRService } from '../services/sr.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { Roadmap } from '../../app/sr/sr.model';
var SRComponent = /** @class */ (function () {
    function SRComponent(srService, toastrService, formBuilder) {
        this.srService = srService;
        this.toastrService = toastrService;
        this.formBuilder = formBuilder;
        this.panelOpenState = false;
        this.displayedColumns = ['monthYear', 'transactionType', 'amount', 'currentbalance', 'remark'];
        this.columnsList = ['monthYear', 'amount'];
        this.loading = false;
        this.srForm = this.formBuilder.group({
            clientId: [null, Validators.required],
            amsIds: null
        });
        this.amsType = null;
        console.log('I am in constructor : ', environment.envName);
    }
    SRComponent.prototype.onChangeEventFunc = function (isChecked) {
        if (isChecked.checked == true) {
            this.amsType = 'matching_only';
        }
        else {
            console.log("What-If AMS - Unchecked");
        }
    };
    SRComponent.prototype.ngOnInit = function () {
        if (localStorage.getItem('clientId') !== null) {
            this.srForm.get('clientId').setValue(localStorage.getItem('clientId'));
        }
    };
    SRComponent.prototype.calculateSR = function (settlement) {
        var _this = this;
        this.loading = true;
        this.srService.calculateSR(settlement, this.amsType).subscribe(function (response) {
            if (response.roadmapResponse != null) {
                _this.settlementResponse = response.roadmapResponse;
                _this.renderResponse();
            }
            else {
                var roadmap = new Roadmap;
                roadmap.remarks = "Roadmap eligible account not found for client id " + settlement.clientId;
                _this.settlementResponse = roadmap;
            }
            _this.loading = false;
        }, function (error) {
            _this.loading = false;
            _this.toastrService.error(error.error.errMessage, 'Error');
        });
        localStorage.setItem('clientId', settlement.clientId);
    };
    SRComponent.prototype.renderResponse = function () {
        var _this = this;
        var responseForecaster = this.settlementResponse.forecaster;
        try {
            if (responseForecaster != null) {
                this.forecaster = new Array();
                var index = 0;
                var start = 0;
                var end = 10;
                while (index < responseForecaster.length) {
                    if (index !== 0) {
                        start = index + 1;
                        end = index + 10;
                    }
                    console.log('start == ' + start + ' end == ' + end);
                    this.forecaster.push(responseForecaster.slice(index, end));
                    index += 10;
                }
                this.regionValuesNew = new Array();
                this.regionValuesKeys = new Array();
                //Region 1
                if (this.settlementResponse.regionDetail.regionOne != undefined) {
                    this.accountsRankMap = this.settlementResponse.regionDetail.regionOne.accountRanks;
                    this.regionValues = this.settlementResponse.regionDetail.regionOne.regionValues;
                    this.lowestDifferntial = this.settlementResponse.regionDetail.regionOne.lowestDifferntial;
                    this.firstSelectedAccount = this.settlementResponse.regionDetail.regionOne.firstSelectedAccount;
                    this.nextAccountsToSettle = this.settlementResponse.regionDetail.regionOne.nextAccountsToSettle;
                    this.debtAccounts = this.settlementResponse.debtAccounts;
                    if (Object.keys(this.firstSelectedAccount).length > 0) {
                        this.regionValuesKeys.push(Object.keys(this.firstSelectedAccount));
                    }
                    if (Object.keys(this.nextAccountsToSettle).length > 0) {
                        this.regionValuesKeys.push(Object.keys(this.nextAccountsToSettle));
                    }
                    if (this.regionValuesKeys.length > 0) {
                        this.regionValuesKeys.forEach(function (accountId) {
                            accountId.forEach(function (acc) {
                                var account = _this.debtAccounts.find(function (x) { return x.accountId === acc; });
                                if (account != undefined && account.regionOneAvailableToSettle) {
                                    var valObj = new Object;
                                    var r1;
                                    var r2;
                                    var r3;
                                    var rank;
                                    var beginningBalance;
                                    var financialReadiness;
                                    var region = 'region1';
                                    r1 = account.r1SettlementAmount;
                                    r1 = r1.toFixed(2);
                                    r2 = account.r2SettlementAmount;
                                    r2 = r2.toFixed(2);
                                    r3 = account.r3SettlementAmount;
                                    r3 = r3.toFixed(2);
                                    var r = _this.firstSelectedAccount[account.accountId] != null;
                                    if (r === undefined) {
                                        r = _this.nextAccountsToSettle[account.accountId] != null;
                                    }
                                    var diff = _this.lowestDifferntial[account.accountId];
                                    diff = diff.toFixed(2);
                                    rank = account.regionOneCreditorRank;
                                    beginningBalance = account.beginningBalance;
                                    beginningBalance = beginningBalance.toFixed(2);
                                    financialReadiness = account.financialReadiness;
                                    valObj = {
                                        'accountId': account.accountId, 'rank': rank, 'r1SettlementAmount': r1, 'r2SettlementAmount': r2, 'r3SettlementAmount': r3,
                                        'diff': diff, 'beginningBalance': beginningBalance, 'fr': financialReadiness, 'selected': r, 'region': region
                                    };
                                    _this.regionValuesNew.push(valObj);
                                }
                            });
                        });
                    }
                }
                //Region 2
                if (this.settlementResponse.regionDetail.regionTwo != undefined) {
                    this.accountsRankMap = this.settlementResponse.regionDetail.regionTwo.accountRanks;
                    this.regionValues = this.settlementResponse.regionDetail.regionTwo.regionValues;
                    this.lowestDifferntial = this.settlementResponse.regionDetail.regionTwo.lowestDifferntial;
                    this.selectedAccount = this.settlementResponse.regionDetail.regionTwo.selectedAccount;
                    this.debtAccounts = this.settlementResponse.debtAccounts;
                    this.regionValuesKeys = Object.keys(this.selectedAccount);
                    var region = 'region2';
                    if (this.regionValuesNew.length == 0) {
                        this.regionValuesNew = new Array();
                    }
                    var _loop_1 = function (key) {
                        account = this_1.debtAccounts.filter(function (x) { return x.accountId === key; });
                        if (account != undefined && account.length > 0 && account[0].regionTwoAvailableToSettle) {
                            valObj = new Object;
                            r2 = account[0].r2SettlementAmount;
                            r2 = r2.toFixed(2);
                            r3 = account[0].r3SettlementAmount;
                            r3 = r3.toFixed(2);
                            r = this_1.selectedAccount[account[0].accountId] != null;
                            diff = this_1.lowestDifferntial[account[0].accountId];
                            diff = diff.toFixed(2);
                            rank = account[0].regionTwoCreditorRank;
                            beginningBalance = account[0].beginningBalance;
                            beginningBalance = beginningBalance.toFixed(2);
                            financialReadiness = account[0].financialReadiness;
                            valObj = {
                                'accountId': account[0].accountId, 'rank': rank, 'r2SettlementAmount': r2, 'r3SettlementAmount': r3,
                                'diff': diff, 'beginningBalance': beginningBalance, 'fr': financialReadiness, 'selected': r, 'region': region
                            };
                            this_1.regionValuesNew.push(valObj);
                        }
                    };
                    var this_1 = this, account, valObj, r2, r3, rank, beginningBalance, financialReadiness, r, diff;
                    for (var _i = 0, _a = this.regionValuesKeys; _i < _a.length; _i++) {
                        var key = _a[_i];
                        _loop_1(key);
                    }
                }
                //Region 3
                if (this.settlementResponse.regionDetail.regionThree != undefined) {
                    this.lowestDifferntial = this.settlementResponse.regionDetail.regionThree.lowestDifferential;
                    this.selectedAccount = this.settlementResponse.regionDetail.regionThree.selectedAccount;
                    this.debtAccounts = this.settlementResponse.debtAccounts;
                    var region = 'region3';
                    if (this.regionValuesNew.length == 0) {
                        this.regionValuesNew = new Array();
                    }
                    this.regionValuesKeys = Object.keys(this.selectedAccount);
                    var _loop_2 = function (key) {
                        account = this_2.debtAccounts.filter(function (x) { return x.accountId === key; });
                        if (account != undefined && account.length > 0 && account[0].regionThreeAvailableToSettle) {
                            valObj = new Object;
                            r3FirstSettlementAmount = account[0].r3FirstSettlementAmount;
                            r3FirstSettlementAmount = r3FirstSettlementAmount.toFixed(2);
                            r3NextSettlementAmount = account[0].r3NextSettlementAmount;
                            r3NextSettlementAmount = r3NextSettlementAmount.toFixed(2);
                            r = this_2.selectedAccount[account[0].accountId] != null;
                            diff = this_2.lowestDifferntial[account[0].accountId];
                            diff = diff.toFixed(2);
                            rank = account[0].regionThreeCreditorRank;
                            beginningBalance = account[0].beginningBalance;
                            beginningBalance = beginningBalance.toFixed(2);
                            financialReadiness = account[0].financialReadiness;
                            valObj = {
                                'accountId': account[0].accountId, 'rank': rank, 'r3FirstSettlementAmount': r3FirstSettlementAmount, 'r3NextSettlementAmount': r3NextSettlementAmount,
                                'diff': diff, 'beginningBalance': beginningBalance, 'fr': financialReadiness, 'selected': r, 'region': region
                            };
                            this_2.regionValuesNew.push(valObj);
                        }
                    };
                    var this_2 = this, account, valObj, r2, r3, rank, beginningBalance, financialReadiness, r3FirstSettlementAmount, r3NextSettlementAmount, r, diff;
                    for (var _b = 0, _c = this.regionValuesKeys; _b < _c.length; _b++) {
                        var key = _c[_b];
                        _loop_2(key);
                    }
                }
                //Region 4
                if (this.settlementResponse.regionDetail.regionFour != undefined) {
                    this.selectedAccount = this.settlementResponse.regionDetail.regionFour.selectedAccount;
                    this.debtAccounts = this.settlementResponse.debtAccounts;
                    var region = 'region4';
                    if (this.regionValuesNew.length == 0) {
                        this.regionValuesNew = new Array();
                    }
                    this.regionValuesKeys = Object.keys(this.selectedAccount);
                    var _loop_3 = function (key) {
                        account = this_3.debtAccounts.filter(function (x) { return x.accountId === key; });
                        if (account != undefined && account.length > 0 && account[0].regionFourAvailableToSettle) {
                            valObj = new Object;
                            r4 = account[0].r4SettlementAmount;
                            var diff_1 = 0.0;
                            rank = account[0].regionFourCreditorRank;
                            beginningBalance = account[0].beginningBalance;
                            beginningBalance = beginningBalance.toFixed(2);
                            financialReadiness = account[0].financialReadiness;
                            r = this_3.selectedAccount[account[0].accountId] != null;
                            valObj = {
                                'accountId': account[0].accountId, 'rank': rank, 'r4SettlementAmount': r4, 'diff': diff_1,
                                'beginningBalance': beginningBalance, 'fr': financialReadiness, 'selected': r, 'region': region
                            };
                            this_3.regionValuesNew.push(valObj);
                        }
                    };
                    var this_3 = this, account, valObj, rank, beginningBalance, financialReadiness, r4, r;
                    for (var _d = 0, _e = this.regionValuesKeys; _d < _e.length; _d++) {
                        var key = _e[_d];
                        _loop_3(key);
                    }
                }
            }
        }
        catch (error) {
            this.loading = false;
            throw new Error(error.message);
        }
    };
    return SRComponent;
}());
export { SRComponent };
