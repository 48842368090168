<div class="login-card" *ngIf=" !(person|async).isLoggedIn">
    <img class="profile-img-card" src="assets/FFN-Logo.png">
    <p class="profile-name-card"></p>
    <form class="form-signin" name="form" #f="ngForm" (ngSubmit)="onSubmit(f)">

        <!-- <span class="diff-container"> </span> -->
        <input class="form-control" type="text" id="username" ngModel name="username" required #username="ngModel" placeholder="Username"
            autofocus="">
        <div class="invalid-feedback" *ngIf="(!username.valid && username.touched) || (isFailedtoLogin && username.untouched)">Please enter a valid username</div>
        <input class="form-control" type="password" id="password" ngModel name="password" required minlength="1" #password="ngModel"
            placeholder="Password">
        <div class="invalid-feedback" *ngIf="(!password.valid && password.touched) || (isFailedtoLogin && password.untouched)">Please enter a valid password</div>
        <p class="invalid-feedback" *ngIf="(person|async).isServiceFailedToLogin"> Please enter valid Username or Password</p>
        <button class="btn btn-primary btn-block btn-lg btn-signin">Sign in</button>
    </form>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
</div>