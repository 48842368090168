var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { LOGIN, LOGIN_SUCCESS_ACTION, LOGIN_FAILURE_ACTION, LOGOUT, AUTO_LOGIN, LOGIN_SUCCESS } from "./user.login.actions";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { of } from "rxjs";
import { LocalStorageService, STORAGE_KEYS } from "../services/localstorage.service";
import { LogintimerService } from "../services/logintimer.service";
import { environment } from "../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/effects";
import * as i2 from "@angular/common/http";
import * as i3 from "@angular/router";
import * as i4 from "../services/localstorage.service";
import * as i5 from "../services/logintimer.service";
var apiURL = environment.AUTH_BASE_API_URL;
var httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*'
    })
};
var PersonEffects = /** @class */ (function () {
    function PersonEffects(actions$, http, router, localStorageService, loginService) {
        var _this = this;
        this.actions$ = actions$;
        this.http = http;
        this.router = router;
        this.localStorageService = localStorageService;
        this.loginService = loginService;
        this.login = this.actions$.pipe(ofType(LOGIN), switchMap(function (loginaction) {
            return _this.http.post(apiURL, {
                'username': loginaction.userData.username, 'password': loginaction.userData.password
            }, httpOptions)
                .pipe(map(function (resData) {
                var username = loginaction.userData.username;
                var currentTime = new Date().getTime();
                _this.localStorageService.storeOnLocalStorage(STORAGE_KEYS.USER_LOGIN, { username: username, isLoggedIn: true, loginTime: currentTime });
                _this.loginService.setLogoutTimer(1000 * 60); // set 1 min
                return new LOGIN_SUCCESS_ACTION({ authenticated: true });
            }), catchError(function (errorRes) {
                return of(new LOGIN_FAILURE_ACTION({ authenticated: false }));
            }));
        }));
        this.autoLoginRedirect = this.actions$.pipe(ofType(AUTO_LOGIN), tap(function () {
            _this.loginService.setLogoutTimer(1000 * 60);
        }));
        this.redirect = this.actions$.pipe(ofType(LOGIN_SUCCESS), tap(function () {
            _this.router.navigate(['/sr']);
        }));
        this.authRedirect = this.actions$.pipe(ofType(LOGOUT), tap(function () {
            _this.localStorageService.clearDataFromLocalStorage();
            _this.loginService.clearLogoutTimer(); // clear timer
            _this.router.navigate(['']);
        }));
    }
    PersonEffects.ngInjectableDef = i0.defineInjectable({ factory: function PersonEffects_Factory() { return new PersonEffects(i0.inject(i1.Actions), i0.inject(i2.HttpClient), i0.inject(i3.Router), i0.inject(i4.LocalStorageService), i0.inject(i5.LogintimerService)); }, token: PersonEffects, providedIn: "root" });
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], PersonEffects.prototype, "login", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], PersonEffects.prototype, "autoLoginRedirect", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], PersonEffects.prototype, "redirect", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], PersonEffects.prototype, "authRedirect", void 0);
    return PersonEffects;
}());
export { PersonEffects };
