<link href="//maxcdn.bootstrapcdn.com/bootstrap/3.3.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//maxcdn.bootstrapcdn.com/bootstrap/3.3.0/js/bootstrap.min.js"></script>
<script src="//code.jquery.com/jquery-1.11.1.min.js"></script>

<hr>
<div class="card">
    <div class="card-header">
        Fuse Model
    </div>
    <div class="card-body">
        <div class="example-container">
            <form class="example-container" [formGroup]="fuseForm" novalidate (ngSubmit)="getFuseData(fuseForm.value)">

                <div class="container">
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <mat-form-field>
                                <input matInput placeholder="Enter Account Id" required formControlName="accountId">
                            </mat-form-field>
                        </div>
                        <div class="col-sm-4">
                            <button type="submit" mat-raised-button color="primary" [disabled]="fuseForm.invalid">Submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

    <div class="card-body" *ngIf="showTable">
        <table class="table table-bordered">
            <thead class="thead-light">
                <th>Age</th>
                <th>Combined Score</th>
                <th>Cummulative Hazard LIT</th>
                <th>Cummulative Hazard RMV</th>
                <th>Loss Value</th>
                <th>Standard Score</th>
            </thead>
            <tr *ngFor="let obj of fuseScoreList">
                <td>{{obj.age}}</td>
                <td>{{obj.combined_score}}</td>
                <td>{{obj.cumm_hazard_lit}}</td>
                <td>{{obj.cumm_hazard_rmv}}</td>
                <td>{{obj.loss_value}}</td>
                <td>{{obj.std_score}}</td>
            </tr>
        </table>
    </div>


</div>