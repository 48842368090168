<link href="//maxcdn.bootstrapcdn.com/bootstrap/3.3.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//maxcdn.bootstrapcdn.com/bootstrap/3.3.0/js/bootstrap.min.js"></script>
<script src="//code.jquery.com/jquery-1.11.1.min.js"></script>
<hr>
<div class="card">
	<div class="card-header">
		Roadmap Request
	</div>
	<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
	<div class="card-body">
		<div class="example-container">

			<form class="example-container" [formGroup]="roadmapForm" novalidate (ngSubmit)="buildRoadmap(roadmapForm.value)">

				<mat-accordion [multi]="true">
					<mat-expansion-panel [expanded]="panelOpenState">
						<mat-expansion-panel-header>
							<mat-panel-title>
								Client
							</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-form-field>
							<input matInput placeholder="Client Id" required formControlName="clientId">
						</mat-form-field>
						<mat-form-field>
							<input matInput placeholder="Client Name" required formControlName="clientName">
						</mat-form-field>
						<mat-form-field>
							<input matInput placeholder="Client State" required formControlName="clientState">
						</mat-form-field>
						<mat-form-field>
							<input matInput placeholder="Draft Amount" required formControlName="draftAmount">
						</mat-form-field>
						<mat-form-field>
							<input matInput placeholder="Fee Percentage" required formControlName="feePercentage">
						</mat-form-field>
						<mat-form-field>
							<input matInput placeholder="Spa Current Balance" formControlName="spaCurrentBalance">
						</mat-form-field>
						<mat-form-field>
							<input matInput placeholder="Fixed Monthly Fees" formControlName="fixedMonthlyFees">
						</mat-form-field>
						<mat-form-field>
							<input matInput placeholder="Settlement Source" formControlName="settlementSource">
						</mat-form-field>
					</mat-expansion-panel>
				</mat-accordion>

				<mat-accordion [multi]="true">
					<mat-expansion-panel [expanded]="panelOpenState" formArrayName="debtAccounts" *ngFor="let item of roadmapForm.get('debtAccounts')['controls']; let i = index;">
						<mat-expansion-panel-header>
							<mat-panel-title>
								Account {{i + 1}} &nbsp; &nbsp; &nbsp; &nbsp;
								<a id="{{i}}" href="javascript:void();" (click)="deleteAccount($event)" class="btn btn-primary a-btn-slide-text">
									<span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
									<span>
										<strong>Delete</strong>
									</span>
								</a>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<div [formGroupName]="i">
							<mat-form-field>
								<input matInput placeholder="Account Id" required formControlName="id">
							</mat-form-field>
							<mat-form-field>
								<input matInput placeholder="Last Payment Date" required formControlName="lastPaymentDate" ngbDatepicker #d="ngbDatepicker"
								 (click)="d.toggle()">
							</mat-form-field>
							<mat-form-field>
								<input matInput placeholder="Current Creditor" required formControlName="currentCreditor" [inputFormatter]="formatter" [resultFormatter]="formatter"
								 [ngbTypeahead]="search">
							</mat-form-field>
							<mat-form-field>
								<input matInput placeholder="Original Creditor" formControlName="originalCreditor" [inputFormatter]="formatter" [resultFormatter]="formatter"
								 [ngbTypeahead]="search">
							</mat-form-field>
							<mat-form-field>
								<input matInput placeholder="Beginning Balance" required formControlName="beginingBalance">
							</mat-form-field>
							<mat-form-field>
								<input matInput placeholder="Current Balance" formControlName="currentBalance">
							</mat-form-field>
						</div>

					</mat-expansion-panel>
				</mat-accordion>
				<div class="row">
					<div class="col-3">
						<a id="1" href="javascript:void();" class="btn btn-primary btn-md" (click)="addDefaultAccounts()">
							<span>
								<strong>Get Default Accounts</strong>
							</span>
						</a>
					</div>
					<div class="col-3">
						<a id="1" href="javascript:void();" class="btn btn-primary btn-md" (click)="addAccount()">
							<span class="glyphicon glyphicon-plus" aria-hidden="true"></span>
							<span>
								<strong>Add More Accounts</strong>
							</span>
						</a>
					</div>
				</div>
				<button type="submit" mat-raised-button color="primary" [disabled]="roadmapForm.invalid">Build Roadmap</button>

			</form>
		</div>
	</div>
</div>