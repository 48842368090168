import { OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RoadmapService } from 'src/app/services/roadmmap.service';
import { DataService } from "src/app/services/data.service";
import { ApplicationConstants } from 'src/app/services/app.constants';
var RoadmapResponseComponent = /** @class */ (function () {
    function RoadmapResponseComponent(roadmapService, toastrService, dataService) {
        this.roadmapService = roadmapService;
        this.toastrService = toastrService;
        this.dataService = dataService;
        this.panelOpenState = true;
        this.displayedColumns = ['monthYear', 'transactionType', 'amount', 'currentbalance', 'remark'];
        this.columnsList = ['monthYear', 'amount'];
        console.log('I am RoadmapResponseComponent constructor');
    }
    RoadmapResponseComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.colorWhite = ApplicationConstants.COLOR_WHITE;
        this.colorMildRed = ApplicationConstants.COLOR_MILD_RED;
        this.dataService.currentMessage.subscribe(function (message) { return _this.settlementResponse = message; });
        if (this.settlementResponse != null && this.settlementResponse.forecaster != null) {
            var responseForecaster = this.settlementResponse.forecaster;
            if (responseForecaster != null) {
                this.forecaster = new Array();
                var index = 0;
                var start = 0;
                var end = 10;
                while (index < responseForecaster.length) {
                    if (index !== 0) {
                        start = index + 1;
                        end = index + 10;
                    }
                    console.log('start == ' + start + ' end == ' + end);
                    this.forecaster.push(responseForecaster.slice(index, end));
                    index += 10;
                }
            }
            if (this.settlementResponse.regionDetail.regionOne != undefined) {
                this.accountsRankMap = this.settlementResponse.regionDetail.regionOne.accountRanks;
                this.regionValues = this.settlementResponse.regionDetail.regionOne.regionValues;
                this.lowestDifferntial = this.settlementResponse.regionDetail.regionOne.lowestDifferntial;
                this.firstSelectedAccount = this.settlementResponse.regionDetail.regionOne.firstSelectedAccount;
                this.nextAccountsToSettle = this.settlementResponse.regionDetail.regionOne.nextAccountsToSettle;
                this.debtAccounts = this.settlementResponse.debtAccounts;
                if (Object.keys(this.firstSelectedAccount).length > 0) {
                    this.regionValuesKeys = Object.keys(this.firstSelectedAccount);
                }
                if (Object.keys(this.nextAccountsToSettle).length > 0) {
                    this.regionValuesKeys.push(Object.keys(this.nextAccountsToSettle));
                }
                this.regionValuesNew = new Array();
                if (this.regionValuesKeys != undefined && this.regionValuesKeys.length > 0) {
                    var _loop_1 = function (key) {
                        account = this_1.debtAccounts.filter(function (x) { return x.accountId === key; });
                        if (account != undefined && account.length > 0 && account[0].regionOneAvailableToSettle) {
                            valObj = new Object;
                            r1 = account[0].r1SettlementAmount;
                            r1 = r1.toFixed(2);
                            r2 = account[0].r2SettlementAmount;
                            r2 = r2.toFixed(2);
                            r3 = account[0].r3SettlementAmount;
                            r3 = r3.toFixed(2);
                            r = this_1.firstSelectedAccount[account[0].accountId] != null;
                            if (r === undefined) {
                                r = this_1.nextAccountsToSettle[account[0].accountId] != null;
                            }
                            diff = this_1.lowestDifferntial[account[0].accountId];
                            diff = diff.toFixed(2);
                            rank = account[0].regionOneCreditorRank;
                            beginningBalance = account[0].beginningBalance;
                            beginningBalance = beginningBalance.toFixed(2);
                            financialReadiness = account[0].financialReadiness;
                            valObj = {
                                'accountId': account[0].accountId, 'rank': rank, 'r1SettlementAmount': r1, 'r2SettlementAmount': r2, 'r3SettlementAmount': r3,
                                'diff': diff, 'beginningBalance': beginningBalance, 'fr': financialReadiness, 'selected': r
                            };
                            this_1.regionValuesNew.push(valObj);
                        }
                    };
                    var this_1 = this, account, valObj, r1, r2, r3, rank, beginningBalance, financialReadiness, r, diff;
                    for (var _i = 0, _a = this.regionValuesKeys; _i < _a.length; _i++) {
                        var key = _a[_i];
                        _loop_1(key);
                    }
                }
            }
            if (this.settlementResponse.regionDetail.regionTwo != undefined) {
                this.accountsRankMapR2 = this.settlementResponse.regionDetail.regionTwo.accountRanks;
                this.regionValuesR2 = this.settlementResponse.regionDetail.regionTwo.regionValues;
                this.lowestDifferntialR2 = this.settlementResponse.regionDetail.regionTwo.lowestDifferntial;
                this.selectedAccountR2 = this.settlementResponse.regionDetail.regionTwo.selectedAccount;
                this.debtAccountsR2 = this.settlementResponse.debtAccounts;
                this.regionValuesKeysR2 = Object.keys(this.selectedAccountR2);
                this.regionValuesNewR2 = new Array();
                var _loop_2 = function (key) {
                    account = this_2.debtAccountsR2.filter(function (x) { return x.accountId === key; });
                    if (account != undefined && account.length > 0 && account[0].regionTwoAvailableToSettle) {
                        valObj = new Object;
                        r2 = account[0].r2SettlementAmount;
                        r2 = r2.toFixed(2);
                        r3 = account[0].r3SettlementAmount;
                        r3 = r3.toFixed(2);
                        r = this_2.selectedAccountR2[account[0].accountId] != null;
                        diff = this_2.lowestDifferntialR2[account[0].accountId];
                        diff = diff.toFixed(2);
                        rank = account[0].regionTwoCreditorRank;
                        beginningBalance = account[0].beginningBalance;
                        beginningBalance = beginningBalance.toFixed(2);
                        financialReadiness = account[0].financialReadiness;
                        valObj = {
                            'accountId': account[0].accountId, 'rank': rank, 'r2SettlementAmount': r2, 'r3SettlementAmount': r3,
                            'diff': diff, 'beginningBalance': beginningBalance, 'fr': financialReadiness, 'selected': r
                        };
                        this_2.regionValuesNewR2.push(valObj);
                    }
                };
                var this_2 = this, account, valObj, r2, r3, rank, beginningBalance, financialReadiness, r, diff;
                for (var _b = 0, _c = this.regionValuesKeysR2; _b < _c.length; _b++) {
                    var key = _c[_b];
                    _loop_2(key);
                }
            }
            //Region 3
            if (this.settlementResponse.regionDetail.regionThree != undefined) {
                this.lowestDifferntialR3 = this.settlementResponse.regionDetail.regionThree.lowestDifferential;
                this.selectedAccountR3 = this.settlementResponse.regionDetail.regionThree.selectedAccount;
                this.debtAccountsR3 = this.settlementResponse.debtAccounts;
                this.regionValuesNewR3 = new Array();
                this.regionValuesKeysR3 = Object.keys(this.selectedAccountR3);
                var _loop_3 = function (key) {
                    account = this_3.debtAccountsR3.filter(function (x) { return x.accountId === key; });
                    if (account != undefined && account.length > 0 && account[0].regionThreeAvailableToSettle) {
                        valObj = new Object;
                        r2 = account[0].r3FirstSettlementAmount;
                        r2 = r2.toFixed(2);
                        r3 = account[0].r3NextSettlementAmount;
                        r3 = r3.toFixed(2);
                        r = this_3.selectedAccountR3[account[0].accountId] != null;
                        diff = this_3.lowestDifferntialR3[account[0].accountId];
                        diff = diff.toFixed(2);
                        rank = account[0].regionThreeCreditorRank;
                        beginningBalance = account[0].beginningBalance;
                        beginningBalance = beginningBalance.toFixed(2);
                        financialReadiness = account[0].financialReadiness;
                        valObj = {
                            'accountId': account[0].accountId, 'rank': rank, 'r2SettlementAmount': r2, 'r3SettlementAmount': r3,
                            'diff': diff, 'beginningBalance': beginningBalance, 'fr': financialReadiness, 'selected': r
                        };
                        this_3.regionValuesNewR3.push(valObj);
                    }
                };
                var this_3 = this, account, valObj, r2, r3, rank, beginningBalance, financialReadiness, r, diff;
                for (var _d = 0, _e = this.regionValuesKeysR3; _d < _e.length; _d++) {
                    var key = _e[_d];
                    _loop_3(key);
                }
            }
        }
    };
    return RoadmapResponseComponent;
}());
export { RoadmapResponseComponent };
